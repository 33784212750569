import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import { getShortMonth } from "./event-actions"

const EventList = ({ max }) => {
  const data = useStaticQuery(graphql`
    query {
      events: allMarkdownRemark(
        filter: { frontmatter: { active: { eq: true }, type: { eq: "event" } } }
        sort: { fields: frontmatter___startDate, order: ASC }
      ) {
        edges {
          node {
            fields {
              id
              slug
            }
            frontmatter {
              title
              type
              host
              startDate
              endDate
              address
              website
            }
            html
            excerpt
          }
        }
      }
    }
  `)

  let showMax = max || data.events.edges.length

  return (
    <ul className="events">
      {data.events.edges.length === 0 && (
        <div>
          <p>
            There are no events scheduled at this time. We look forward to
            seeing you soon. Stay safe.
          </p>
          <p>
            <a href={"/contact"}>Contact us</a> for all your product training
            needs.
          </p>
        </div>
      )}
      {data.events.edges.map((edge, i) => {
        const event = edge.node
        const daysBetween =
          Math.round(
            (new Date(event.frontmatter.endDate) -
              new Date(event.frontmatter.startDate)) /
              (1000 * 60 * 60 * 24)
          ) + 1
        if (showMax > 0) {
          showMax--
          return (
            <li key={`event-${i}`} className="event clearfix">
              <div className="event-stamp">
                <div className="short-month">
                  {getShortMonth(event.frontmatter.startDate)}
                </div>
                <div className="date-num">
                  {event.frontmatter.startDate.split("-")[2]}
                </div>
                <div className="status">{`${daysBetween} Day${
                  daysBetween > 1 ? `s` : ``
                }`}</div>
              </div>
              <div className="event-info">
                <a href={`${event.fields.slug}`} className="event-name">
                  {event.frontmatter.title}
                </a>
                {/*<p className="event-host">{event.frontmatter.host}</p>*/}
                <p className="event-date">
                  {event.frontmatter.startDate} &ndash;{" "}
                  {event.frontmatter.endDate}
                </p>
                <p className="event-address">{event.frontmatter.address}</p>
              </div>
              <div className="event-description">
                <p className="label">Description</p>
                <div dangerouslySetInnerHTML={{ __html: event.excerpt }} />
              </div>
            </li>
          )
        }
      })}
    </ul>
  )
}

export default EventList
