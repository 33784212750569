import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EventList from "../events/events-list"

export default () => {
  console.log("Page Context")

  return (
    <Layout>
      <SEO title={`Training`} />
      {/* <h1>Training</h1>
      <TrainingList/>
      <hr/> */}
      <h1>Events</h1>
      <EventList />
    </Layout>
  )
}
